import styled, { css } from "styled-components";

export const CarouselContainer = styled.div`
  padding-top: 10rem;
  width: 100%;
`;

export const TitleWrapper = styled.div`
${({ theme }) => css`
  display: flex;
  padding-left: 60rem;

    @media (max-width: ${theme.breakpoint.carouselBreakpoint}) {
      padding-left: 30rem;
    }
    
    @media (max-width: ${theme.breakpoint.desktop}) {
      padding-left: 10rem;
    }
    @media (max-width: ${theme.breakpoint.tablet}) {
      padding-left: 5rem;
      padding-right: 2rem;
    }
  `}
`;

export const ParagraphWrapper = styled.div`
${({ theme }) => css`
  display: flex;
  padding-top: 3rem;
  padding-left: 60rem;
  padding-right: 2rem;

    @media (max-width: ${theme.breakpoint.carouselBreakpoint}) {
      padding-left: 30rem;
    }

    @media (max-width: ${theme.breakpoint.desktop}) {
      padding-left: 10rem;
    }
    @media (max-width: ${theme.breakpoint.tablet}) {
      padding-right: 5rem;
      padding-left: 5rem;
    }
  `}
`;

export const ButtonWrapper = styled.div`
${({ theme }) => css`
  display: inline-flex;
  padding-left: 110rem;

    @media (max-width: ${theme.breakpoint.carouselBreakpoint}) {
      padding-left: 60rem;
    }
    @media (max-width: ${theme.breakpoint.desktop}) {
      padding-left: 30rem;
    }  
    @media (max-width: ${theme.breakpoint.tablet}) {
      padding-left: 27rem;
    }
    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      padding-left: 20rem;
    }
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      padding-left: 15rem;
    }
    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      padding-left: 10rem;
    }
  `}
`;

export const LinkWrapper = styled.div`
  display: flex;
  padding-top: 2rem;
`;

export const CarouselTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color200};
    font-family: "Mulish", sans-serif;
    font-size: 6rem;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;
    
    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 5rem;
    }
    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 4rem;
    }
    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 3.5rem;
    }
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      font-size: 3rem;
    }
    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      font-size: 2.5rem;
    }
  `}
`;

export const CarouselParagraph = styled.p`
  ${({ theme }) => css`
    margin-top: 3rem;
    margin-bottom: 5.8rem;
    color: ${theme.colors.hex.gray.color200};
    font-family: "Mulish", sans-serif;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.6rem;
    text-align: justify;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2rem;
      line-height: 3.2rem;
    }
    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 1.8rem;
      line-height: 3rem;
    }
    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  `}
`;
