import styled, { css } from "styled-components";
import { FaBars, FaTimes } from "react-icons/fa";

export const Container = styled.header`
  ${({ theme }) => css`
    width: 100%;
    height: 10rem;
    background: ${theme.colors.hex.black.color900};
    display: flex;
    justify-content: center;
    padding: 2rem;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `} 
`;

export const MenuIcon = styled(FaBars)`
  ${({ theme }) => css`
    font-size: 2rem;
    color: white;
    cursor: pointer;
    @media (min-width: ${theme.breakpoint.tablet}) {
      display: none;
    }
  `}
`;

export const NavigationMenu = styled.div<{ isMenuOpen: boolean }>`
  ${({ theme, isMenuOpen }) => css`
    position: fixed;
    top: 0;
    right: ${isMenuOpen ? "0" : "-100%"};
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    transition: right 0.3s ease-in-out;
    z-index: 998;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: ${theme.breakpoint.tablet}) {
      display: flex;
      position: static;
      flex-direction: row;
      background: none;
      height: auto;
      width: auto;
      right: auto;
      align-items: end;
    }
  `}
`;

export const ButtonContainer = styled.div`
  ${({ theme }) => css`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  

  @media (max-width: ${theme.breakpoint.tablet}) {
      flex-direction: column;
      gap: 2rem;
      padding: 1.5rem;
      margin-top: 0;

      button {
        width: 100%;
      }
    }
  `}
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 997;
`;

export const CloseIcon = styled(FaTimes)`
${({ theme }) => css`
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;

    &:hover {
      transform: translateY(-0.2rem);
    }

    &:active {
      transform: translateY(0.2rem);
    }

    @media (min-width: ${theme.breakpoint.tablet}) {
      display: none;
    }
  `}
`;
