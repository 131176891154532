import React from "react";
import { Banner } from "../../components/molecules/Banner";
import {
  BannerTitle,
  BannerParagraph,
  CarouselTitle,
  CarouselParagraph,
  TitleColored,
  CarouselContainer,
  TitleWrapper,
  ParagraphWrapper,
  ButtonWrapper,
} from "./styles";
import { Button } from "../../components/atoms/Button";
import { Carousel } from "../../components/organisms/Carousel";
import { SwiperItem } from "../../components/molecules/SwiperItem";
import { SwiperSlide } from "swiper/react";
import { ContainerHelp } from "../../components/organisms/WaysToHelp";
import { CommunityActions } from "../../components/organisms/CommunityActions";
import backgroundImage from "../../assets/img/banner.png";
import homeSwiperImg1 from "../../assets/img/home-swiper-img1.jpg";
import homeSwiperImg2 from "../../assets/img/home-swiper-img2.png";
import homeSwiperImg3 from "../../assets/img/home-swiper-img3.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Router";
import { ScrollButton } from "../../components/atoms/ScrollButton";

export const Home: React.FC = () => {
  return (
    <>
      <Carousel color="secondary-swiper" loop>
        <SwiperSlide>
          <SwiperItem backgroundImage={homeSwiperImg1}>
            <CarouselContainer>
              <TitleWrapper>
                <CarouselTitle>
                  Bem-vindo à <TitleColored>SOLPRA</TitleColored> - Sociedade
                  Lagunense de Proteção aos Animais.
                </CarouselTitle>
              </TitleWrapper>
              <ParagraphWrapper>
                <CarouselParagraph>
                  Uma associação sem fins lucrativos com um propósito nobre:
                  transformar a realidade dos cães e gatos rejeitados e
                  abandonados, proporcionando-lhes o respeito e a dignidade que
                  merecem.
                </CarouselParagraph>
              </ParagraphWrapper>
              <ButtonWrapper>
              <Link to={ROUTES.CONSTRUCTION}>
                <Button variant="contained">SAIBA COMO AJUDAR</Button>
              </Link>
              </ButtonWrapper>
            </CarouselContainer>
          </SwiperItem>
        </SwiperSlide>

        <SwiperSlide>
          <SwiperItem backgroundImage={homeSwiperImg2}>
            <CarouselContainer>
              <TitleWrapper>
                <CarouselTitle>
                  Bem-vindo à <TitleColored>SOLPRA</TitleColored> - Sociedade
                  Lagunense de Proteção aos Animais.
                </CarouselTitle>
              </TitleWrapper>
              <ParagraphWrapper>
                <CarouselParagraph>
                  Uma associação sem fins lucrativos com um propósito nobre:
                  transformar a realidade dos cães e gatos rejeitados e
                  abandonados, proporcionando-lhes o respeito e a dignidade que
                  merecem.
                </CarouselParagraph>
              </ParagraphWrapper>
              <ButtonWrapper>
              <Link to={ROUTES.CONSTRUCTION}>
                <Button variant="contained">SAIBA COMO AJUDAR</Button>
              </Link>
              </ButtonWrapper>
            </CarouselContainer>
          </SwiperItem>
        </SwiperSlide>

        <SwiperSlide>
          <SwiperItem backgroundImage={homeSwiperImg3}>
            <CarouselContainer>
              <TitleWrapper>
                <CarouselTitle>
                  Bem-vindo à <TitleColored>SOLPRA</TitleColored> - Sociedade
                  Lagunense de Proteção aos Animais.
                </CarouselTitle>
              </TitleWrapper>
              <ParagraphWrapper>
                <CarouselParagraph>
                  Uma associação sem fins lucrativos com um propósito nobre:
                  transformar a realidade dos cães e gatos rejeitados e
                  abandonados, proporcionando-lhes o respeito e a dignidade que
                  merecem.
                </CarouselParagraph>
              </ParagraphWrapper>
              <ButtonWrapper>
                <Link to={ROUTES.CONSTRUCTION}>
                  <Button variant="contained">SAIBA COMO AJUDAR</Button>
                </Link>
              </ButtonWrapper>
            </CarouselContainer>
          </SwiperItem>
        </SwiperSlide>
      </Carousel>
      <ContainerHelp />
      <Banner backgroundImage={backgroundImage}>
        <BannerTitle>Sou um abrigo</BannerTitle>
        <BannerParagraph>
          Somos um abrigo comprometido em proporcionar cuidado e amor a cada
          animal. Aqui, trabalhamos incansavelmente para garantir que todos os
          pets encontrem um lar carinhoso. Junte-se a nós nessa missão de
          transformar vidas. Seja parte dessa história de amor e esperança!
        </BannerParagraph>
        <Link to={ROUTES.CONSTRUCTION}>
          <Button variant="contained" color="banner">
            QUERO ABRIGAR
          </Button>
        </Link>
      </Banner>
      <CommunityActions />
      <ScrollButton />
    </>
  );
};