import styled , { css } from 'styled-components'

export const Hero = styled.section`
 ${({ theme }) => css`
  width: 100%;
  min-height: 100vh;
  padding: 10rem 0;
  padding-top: 16.5rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  @media (max-width: ${theme.breakpoint.desktop}) {
    background-attachment: scroll;
    padding-top: 14rem;
    padding-bottom: 8rem;
  }

  @media (max-width: ${theme.breakpoint.tablet}) {
    padding-top: 12rem;
    padding-bottom: 6rem;
    min-height: 90vh;
  }

  @media (max-width: ${theme.breakpoint.mobileLarge}) {
    background-size: cover;
    padding-top: 10rem;
    padding-bottom: 5rem;
    min-height: 80vh;
  }
  
  @media (max-width: ${theme.breakpoint.mobileMedium}) {
    padding-top: 8rem;
    padding-bottom: 4rem;
    min-height: 75vh;
  }
  
  @media (max-width: ${theme.breakpoint.mobileSmall}) {
    padding-top: 6rem;
    padding-bottom: 3rem;
    min-height: 70vh;
  }
`}
`

export const Wrapper = styled.div` 
   ${({ theme }) => css`
   width: 100%;
   max-width: ${theme.breakpoint.maxWidth};
   padding: 0 2rem;
   box-sizing: border-box;
  `}
`
