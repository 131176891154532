import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  ${({ theme }) => css`
    background: ${theme.colors.hex.black.color900};
    color: ${theme.colors.hex.primary.color600};
    font-family: ${theme.fonts.inter};
    padding-top: 10rem;
    padding-bottom: 4.3rem;
    display: flex;
    justify-content: center;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 14.9rem;
  `}
`;

export const BoxFlexColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;

    @media (max-width: ${theme.breakpoint.tablet}) {
      flex-direction: column;
      gap: 4rem;
      align-items: center;
    }
  `}
`;

export const DivFlexColumn = styled.div`
  width: 33.3333%;
  display: flex;
  justify-content: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  text-align: center;
`;

export const Subtitle = styled.p`
  font-size: 2.4rem;
  font-weight: 700;
`;

export const LinkEmail = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    text-decoration: none;
    font-size: 2.4rem;
    font-weight: 400;
  `}
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    text-decoration: none;
    cursor: pointer;
    font-size: 2.4rem;
    font-weight: 400;
  `}
`;

export const StyledLinkButton = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    text-decoration: none;
    cursor: pointer;
    font-size: 2.4rem;
    font-weight: 400;
  `}
`;

export const CopyrightMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color900};
    font-family: ${theme.fonts.inter};
    font-size: 2.4rem;
    font-weight: 400;
    text-align: center;
  `}
`;

export const SocialIconContainer = styled.div`
  display: flex;
  gap: 5rem;
`;

export const Separator = styled.div`
  ${({ theme }) => css`
    width: 65%;
    height: 0.3rem;
    background-color: ${theme.colors.hex.primary.color600};
    margin: 2rem 0;
    box-shadow: 0rem 0.5rem 0.5rem 0rem rgba(0, 0, 0, 0.25);

    @media (min-width: ${theme.breakpoint.tablet}) {
      display: none;
    }
  `}
`;
