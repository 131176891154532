import React from "react";
import {
  Container,
  StyledImage,
  StyledTitle,
  StyledDescription,
} from "./styles";
import { Button } from "../../atoms/Button";


interface CardProps {
  imageSrc?: string;
  altText: string;
  title: string;
  description: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

export const Card: React.FC<CardProps> = ({
  imageSrc,
  altText,
  title,
  description,
  buttonText,
  onButtonClick,
}) => {
  return (
      <Container>
          <StyledImage src={imageSrc} alt={altText} />
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>{description}</StyledDescription>
        <Button onClick={onButtonClick} variant="contained" color="banner">
          
          {buttonText}
        </Button>
      </Container>
  );
};
