import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 40rem;
    height: 55rem;
    border: 2px solid ${theme.colors.hex.black.color900};
    border-radius: 1rem;
    background-color: ${theme.colors.hex.black.color900};

    @media (max-width: ${theme.breakpoint.desktop}) {
      width: 30rem;
      height: auto;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      width: 40rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      width: 100%;
    }
  `}
  
  > img {
    width: 100%;
    object-fit: cover;
    border-radius: 1rem 1rem 0 0;
  }
`;

export const Title = styled.p`
  ${({ theme }) => css`
    width: 100%;
    height: 5rem;
    color: ${theme.colors.hex.primary.color600};
    font-size: 2.2rem;
    font-weight: 800;

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 2rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.8rem;
    }
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    width: 100%;
    height: 5rem;
    color: ${theme.colors.hex.white};
    margin: 2rem 0;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: normal;
    
    @media (max-width: ${theme.breakpoint.desktop}) {
      padding: 2rem 0 8rem 0;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 1.6rem;
      margin: 0;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.4rem;
    }
  `}
`;

export const Content = styled.div`
  height: 100%;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
`;

  export const StyledLink = styled(Link)`
    ${({ theme }) => css`
      color: ${theme.colors.hex.gray.color800};
      font-size: 2.4rem;
      font-style: italic;
      text-decoration-line: underline;
      text-align: end;
      margin-top: auto;

      @media (max-width: ${theme.breakpoint.tablet}) {
        font-size: 2rem;
        margin-top: 3rem;
      }

      @media (max-width: ${theme.breakpoint.mobileLarge}) {
        font-size: 1.8rem;
      }
    `}
  `;

export const ImageCommunity = styled.img`
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0 0.25rem 0.25rem 0;
`;