import React from "react";
import {
  Container,
  TextContainer,
  GenderImgContainer,
  InfoContainer,
} from "./styles";
import { Button } from "../../atoms/Button";
import { PetInfo } from "../../../../domain/entities/Pet";
import MaleSymbol from "../../../assets/img/MaleSymbol.png";
import FemaleSymbol from "../../../assets/img/FemaleSymbol.png";

interface GalleryCardProps {
  petInfo: PetInfo;
}

export const GalleryCard: React.FC<GalleryCardProps> = ({ petInfo }) => {
  return (
    <Container>
      <img src={petInfo.image} alt= "Pet image" />
      <InfoContainer>
        <TextContainer>
          <span>{petInfo.characteristics}</span>
        </TextContainer>
        <GenderImgContainer>
          {petInfo.gender === "M" ? (
            <img src={MaleSymbol} alt="Macho" />
          ) : (
            <img src={FemaleSymbol} alt="Fêmea" />
          )}
        </GenderImgContainer>
      </InfoContainer>
      <Button
        onClick={() => {
          console.log(petInfo);
        }}
        variant="GalleryCardButton"
        color="primary"
      >
        Quero adotar{" "}
      </Button>
    </Container>
  );
};
