import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.hex.black.color900};
    width: 100%;
    max-width: 87rem;
    height: 50rem;
    display: flex;
    border-radius: 1rem;
    align-items: center;
    border: 0.1rem solid ${theme.colors.hex.black.color900};
    
    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      flex-direction: column;
      height: auto;
    }
  `}
`;

export const ImgContainer = styled.div<{ name: string }>`
  ${({ theme, name }) => css`
    display: flex;
    border-radius: 1rem 0 0 1rem;
    width: 45%;
    height: 50rem;
    overflow: hidden;
    border: 0.1rem solid ${theme.colors.hex.black.color900};

    
    img {
      width: 100%;
      object-fit: cover;

      ${name === "Giuliana Santana" && css`
        @media (max-width: ${theme.breakpoint.desktop}) {
          object-position: 60% 0%;
        }
      `}

      ${name === "Marcel" && css`
        object-position: left;

        @media (max-width: ${theme.breakpoint.tablet}) {
          object-position: 0% 25%;
        }
      `}

      ${name === "Felipe Tasso" && css`
        object-position: 70% 0%;
      `}

      ${name === "Victor Jonsson" && css`
        object-position: 70% 0%;
      `}
    }


    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      width: 100%;
      border-radius: 1rem 1rem 0 0;
    }
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 55%;
    padding: 5.6rem;

    @media (max-width: ${theme.breakpoint.maxWidth}) {
      padding: 4rem;
    }

    @media (max-width: ${theme.breakpoint.desktop}) {
      padding: 2rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      align-items: center;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      width: 100%;
    }

    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      padding: 1.6rem;
      text-align: center;
      height: 35rem;
    }
  `}
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.standard};
    font-size: 2.8rem;
    font-style: italic;
    font-weight: 300;
    line-height: 3.6rem;

    @media (max-width: ${theme.breakpoint.maxWidth}) {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  `}
`;

export const NameContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color800};
    font-family: ${theme.fonts.standard};
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 3.6rem;
    margin-top: 3rem;
    margin-left: auto;
    text-align: right;
    white-space: nowrap;

    @media (max-width: ${theme.breakpoint.maxWidth}) {
      font-size: 3rem;
      line-height: 3.8rem;
    }

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2.3rem;
      line-height: 2.8rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 2.4rem;
      line-height: 2.4rem;
      white-space: normal;
    }
  `}
`;

export const PositionContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    font-family: ${theme.fonts.standard};
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3.6rem;
    margin-left: auto;
    text-align: right;
    white-space: nowrap;

    @media (max-width: ${theme.breakpoint.maxWidth}) {
      line-height: 3.8rem;
    }
    
    @media (max-width: ${theme.breakpoint.desktop}) {
      white-space: normal;
    }
    
    @media (max-width: ${theme.breakpoint.tablet}) {
      line-height: 2.4rem;
      margin-top: 0.5rem;
    }

    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      height: 5rem;
    }
  `}
`;

export const TeamSwiper = styled.div`
  ${({ theme }) => css`  
    position: relative;
    .swiper-button-next,
    .swiper-button-prev {
      color: ${theme.colors.hex.primary.color600};
    }
  `}
`;

export const ButtonTeam = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    left: -4rem;
    right: -4rem;

    > button {
      color: ${theme.colors.hex.primary.color600};
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      > svg path {
        stroke: ${theme.colors.hex.primary.color600};
      }

      &:disabled {
        opacity: 0.3;
      }
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      left: -2.3rem;
      right: -2.3rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      display: none;
    }

  `}
`;
