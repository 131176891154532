import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: content;
    padding: 4rem 6rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 16.5rem;
    display: flex;
    justify-content: center;

    @media (max-width: ${theme.breakpoint.desktop}) {
      padding: 4rem 3rem;
      max-width: 100%;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      padding: 3.6rem 2.6rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      padding: 2.8rem 2rem;
      background-position: left;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};

    @media (max-width: ${theme.breakpoint.desktop}) {
      padding: 0 2rem;
      max-width: 100%;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      padding: 0 1rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      padding: 0 0.5rem;
    }
  `}
`;
