import {
  ActionReducerMapBuilder,
  createReducer,
  PayloadAction,
  Reducer,
} from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import { UserInfo } from "../entities/auth";
import { postUserThunk } from "../thunks/auththunks";

export type UserActionType = PayloadAction<any>;

export interface AuthState {
  user: UserInfo;
  isLoading: boolean;
}

export const USER_INITIAL_STATE: AuthState = {
  user: {
    id: "",
    name: "",
    email: "",
    phone: "",
    responsible: "",
    role:"",
    access_token: "",
    refresh_token: "",
  },
  isLoading: false,
};

export const userSelector = (state: EngageState): AuthState => state.auth;

function handlePendingThunk(state: AuthState): AuthState {
  return {
    ...state,
    isLoading: true,
  };
}

function handleRejectedThunk(state: AuthState): AuthState {
  return {
    ...state,
    isLoading: false,
  };
}

function handlePostUserThunk(
  state: AuthState,
  action: PayloadAction<UserInfo>
): AuthState {
  return {
    ...state,
    user: action.payload,
    isLoading: false,
  };
}

const builder = (builder: ActionReducerMapBuilder<AuthState>) =>
  builder
    .addCase(postUserThunk.pending.type, handlePendingThunk)
    .addCase(postUserThunk.rejected.type, handleRejectedThunk)
    .addCase(postUserThunk.fulfilled.type, handlePostUserThunk);

export const authReducer: Reducer<AuthState, UserActionType> = createReducer(
  USER_INITIAL_STATE,
  builder
);
