import React, { useState } from "react";
import {
  FooterContainer,
  Wrapper,
  BoxFlexColumn,
  InfoContainer,
  Subtitle,
  LinkEmail,
  LinkContainer,
  CopyrightMessage,
  SocialIconContainer,
  StyledLink,
  StyledLinkButton,
  DivFlexColumn,
  Separator,
} from "./styles";
import { ReactComponent as IconFacebook } from "../../../assets/img/social/IconFacebook.svg";
import { ReactComponent as IconInstagram } from "../../../assets/img/social/IconInstagram.svg";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { ROUTES } from "../../../Router";
import { Modal } from "../../organisms/Modal";
import { Link } from "react-router-dom";

export const Footer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  return (
    <FooterContainer>
      <Wrapper>
        <BoxFlexColumn>
          <DivFlexColumn>
            <Link to={ROUTES.ROOT}>
              <Logo />
            </Link>
          </DivFlexColumn>
          <Separator/>
          <DivFlexColumn>
            <InfoContainer>
              <Subtitle>Contato</Subtitle>
              <div>
                <LinkEmail
                  href="mailto:contato@solpra.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contato@solpra.com.br
                </LinkEmail>
              </div>
              <SocialIconContainer>
                <a
                  href="https://www.facebook.com/profile.php?id=100064759477109"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconFacebook />
                </a>
                <a
                  href="https://www.instagram.com/solpravoluntariado/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconInstagram />
                </a>
              </SocialIconContainer>
            </InfoContainer>
          </DivFlexColumn>
          <Separator/>
          <DivFlexColumn>
            <InfoContainer>
              <Subtitle>Links Rápidos</Subtitle>
              <LinkContainer>
                <StyledLink to={ROUTES.ROOT}>Home</StyledLink>
                <StyledLink to={ROUTES.INSTITUTIONAL}>Institucional</StyledLink>
                <StyledLink to={ROUTES.ADOPTION}>Adoção</StyledLink>
                <StyledLink to={ROUTES.CONSTRUCTION}>Blog</StyledLink>
                <StyledLinkButton onClick={() => setIsModalOpen(true)}>
                  Doar
                </StyledLinkButton>
                <StyledLink to={ROUTES.CONSTRUCTION}>Login</StyledLink>
              </LinkContainer>
            </InfoContainer>
          </DivFlexColumn>
        </BoxFlexColumn>
        <CopyrightMessage>Solpra. Alguns direitos reservados.</CopyrightMessage>
      </Wrapper>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} isQRCodeModal={true} />
    </FooterContainer>
  );
};
