import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 16.5rem 2rem;
    display: flex;
    justify-content: center;
    
    @media (max-width: ${theme.breakpoint.desktop}) {
      padding-inline: 7rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      padding: 12rem 4rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      padding: 10rem 2rem;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      padding: 0 1rem;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};    
    font-size: 6rem;
    text-align: center;    

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 5rem;   
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 4.5rem;
    }
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: 2.8rem;
    font-weight: 600;
    text-align: center;
    
    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 2.4rem;
      max-width: 90%;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.8rem;
      max-width: 100%;
    }
  `}
`;

export const CardContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5.9rem;
    gap: 2rem;

    @media (max-width: ${theme.breakpoint.desktop}) {
      gap: 1.5rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      align-items: center;
      flex-direction: column;
      gap: 5rem;
    }
  `}
`;