import styled, { css } from 'styled-components'

export const Container = styled.div`
${({ theme }) => css`
    width: 100%;
    height: 96rem;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;

    @media (max-width: ${theme.breakpoint.tablet}) {
    height: 85rem;
    }
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
    height: 80rem;
    }
    @media (max-width: ${theme.breakpoint.mobileSmall}) {
    height: 75rem;
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
  
    @media (max-width: ${theme.breakpoint.carouselBreakpoint}) {
    max-width: 80rem;
    }
    @media (max-width: ${theme.breakpoint.desktop}) {
    max-width: 50rem;
    }
  `}
`
