import styled, { css } from "styled-components";

export const CarouselContainer = styled.div`
  padding-top: 19rem;
  width: 100%;
`;

export const TitleWrapper = styled.div`
${({ theme }) => css`
  width: 80rem;
  display: flex;
  padding-left: 2rem;

    @media (max-width: ${theme.breakpoint.desktop}) {
      max-width: 100%;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      padding-right: 2rem;
    }
  `}
`;

export const ParagraphWrapper = styled.div`
${({ theme }) => css`
  width: 66rem;
  display: flex;
  padding-top: 3.2rem;
  padding-left: 2rem;
  
    @media (max-width: ${theme.breakpoint.desktop}) {
      max-width: 100%;
    }
    @media (max-width: ${theme.breakpoint.tablet}) {
      padding-top: 2.5rem;
      padding-right: 2rem;
    }
  `}
`;

export const CarouselTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color200};
    font-family: ${theme.fonts.standard};
    display: inline;
    font-size: 6rem;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 5rem;
    }
    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 4rem;
    }
    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 3.5rem;
    }
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      font-size: 3rem;
    }
    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      font-size: 2.5rem;
    }
  `}
`;

export const CarouselParagraph = styled.p`
  ${({ theme }) => css`
    margin-top: 3rem;
    margin-bottom: 9.6rem;
    max-width: 100%;
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.lato};
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.6rem;
    text-align: justify;
    
    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2rem;
      line-height: 3.2rem;
    }
    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 1.8rem;
      line-height: 3rem;
    }
    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      font-size: 1.4rem;
      line-height: 2.4rem;
    }
    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 2rem;
`;

export const TitleColored = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
  `}
`;

export const BannerTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standart};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 5.6rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 4.8rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 3.8rem;
    }
  `}
`;

export const BannerParagraph = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.lato};
    font-size: 2.8rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 12.4rem;
    width: 68rem;
    max-width: 100%;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2.6rem;
      width: 50rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 2.2rem;
      width: 40rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.8rem;
      width: 100%;
    }
  `}
`;
