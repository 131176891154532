import React from "react";
import { SwiperSlide } from "swiper/react";
import { Carousel } from "../../components/organisms/Carousel";
import { Gallery } from "../../components/organisms/Gallery";
import { SwiperItem } from "../../components/molecules/SwiperItem";
import { CommunityActions } from "../../components/organisms/CommunityActions";
import {
  CarouselContainer,
  CarouselParagraph,
  CarouselTitle,
  LinkWrapper,
  ParagraphWrapper,
  TitleWrapper,
  ButtonWrapper,
} from "./styles";
import adoptionSwiperImg1 from "../../assets/img/adoption-swiper-img1.png";
import adoptionSwiperImg2 from "../../assets/img/adoption-swiper-img2.png";
import adoptionSwiperImg3 from "../../assets/img/adoption-swiper-img3.png";
import { Button } from "../../components/atoms/Button";
import { ScrollButton } from "../../components/atoms/ScrollButton";

export const Adoption: React.FC = () => {
  return (
    <>
      <Carousel color="secondary-swiper" loop>
        <SwiperSlide>
          <SwiperItem backgroundImage={adoptionSwiperImg1}>
              <CarouselContainer>
                <TitleWrapper>
                  <CarouselTitle>Depoimentos dos doguinhos</CarouselTitle>
                </TitleWrapper>
                <ParagraphWrapper> 
                    <CarouselParagraph>
                      “Eu não sou cachorro, não Pra viver tão humilhado. Eu não sou
                      cachorro, não Para ser tão desprezado. Tu não sabes
                      compreender quem te ama, quem te adora. Tu só sabes
                      maltratar-me. E por isso eu vou embora..”
                    </CarouselParagraph>
                </ParagraphWrapper>
                <ButtonWrapper>                
                  <LinkWrapper>
                    <Button
                      variant="contained"
                      onClick={() =>
                        window.open(
                          "https://www.letras.mus.br/waldick-soriano/",
                          "_blank"
                        )
                      }
                    >
                      Waldick Soriano
                    </Button>
                  </LinkWrapper>
                </ButtonWrapper>
              </CarouselContainer>
          </SwiperItem>
        </SwiperSlide>

        <SwiperSlide>
          <SwiperItem backgroundImage={adoptionSwiperImg2}>
              <CarouselContainer>
                <TitleWrapper>
                  <CarouselTitle>Depoimentos dos doguinhos</CarouselTitle>
                </TitleWrapper>
                <ParagraphWrapper>
                  <CarouselParagraph>
                    “Eu não sou cachorro, nãoPra viver tão humilhadoEu não sou
                    cachorro, nãoPara ser tão desprezado.Tu não sabes
                    compreenderQuem te ama, quem te adoraTu só sabes
                    maltratar-meE por isso eu vou embora..”
                  </CarouselParagraph>
                </ParagraphWrapper>
                <ButtonWrapper>  
                  <LinkWrapper>
                    <Button
                      variant="contained"
                      onClick={() =>
                        window.open(
                          "https://www.letras.mus.br/waldick-soriano/",
                          "_blank"
                        )
                      }
                    >
                      Waldick Soriano
                    </Button>
                  </LinkWrapper>
                </ButtonWrapper>  
              </CarouselContainer>
          </SwiperItem>
        </SwiperSlide>

        <SwiperSlide>
          <SwiperItem backgroundImage={adoptionSwiperImg3}>
              <CarouselContainer>
                <TitleWrapper>
                  <CarouselTitle>Depoimentos dos doguinhos</CarouselTitle>
                </TitleWrapper>
                <ParagraphWrapper>
                  <CarouselParagraph>
                    “Eu não sou cachorro, nãoPra viver tão humilhadoEu não sou
                    cachorro, nãoPara ser tão desprezado.Tu não sabes
                    compreenderQuem te ama, quem te adoraTu só sabes
                    maltratar-meE por isso eu vou embora..”
                  </CarouselParagraph>
                </ParagraphWrapper>
                <ButtonWrapper>
                  <LinkWrapper>
                    <Button
                      variant="contained"
                      onClick={() =>
                        window.open(
                          "https://www.letras.mus.br/waldick-soriano/",
                          "_blank"
                        )
                      }
                    >
                      Waldick Soriano
                    </Button>
                  </LinkWrapper>
                </ButtonWrapper>
              </CarouselContainer>
          </SwiperItem>
        </SwiperSlide>
      </Carousel>

      <Gallery />
      <CommunityActions />
      <ScrollButton />
    </>
  );
};
