import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 31rem;
    height: 65rem;
    display: flex;
    flex-direction: column;

    > button {
      margin-top: auto;
    }

    @media (max-width: ${theme.breakpoint.desktop}) {
      width: 20rem;
      height: 55rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      width: 34rem;
      height: 73rem;
    }
    
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      width: 30rem;
      height: 66rem;
    }

    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      width: 25rem;
      height: 60rem;
    }
  `}
`;


export const StyledImage = styled.img`
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0rem 0.5rem 0.5rem 0rem rgba(0, 0, 0, 0.25); 
`;

export const StyledTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    font-size: 2.2rem;
    font-weight: 700;
    margin-top: 3.8rem;
    margin-bottom: 1.2rem;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 3.8rem;
    }

    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      font-size: 3.4rem;
    }

    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      font-size: 2.6rem;
    }
  `}
`;

export const StyledDescription = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.lato};
    font-size: 1.8rem;
    font-weight: 400;

  `}
`;



