import React from "react";
import CommunityActionsCard from "../../molecules/CommunityActionsCard";
import { Container, Wrapper, Title, CardContainer, Description } from "./styles";
import coupleAndCat from "../../../assets/img/coupleAndCat.svg";
import dogWithHat from "../../../assets/img/dogWithHat.svg";
import vetAndDog from "../../../assets/img/vetAndDog.svg";
import { ROUTES } from "../../../Router";

export const CommunityActions: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <Title>Acompanhe no blog</Title>
        <Description>
          Acompanhe nosso blog e faça parte da comunidade que ama e cuida dos animais!
        </Description>
        <CardContainer>
          <CommunityActionsCard
            title="Treinamento “Pais & Pets”"
            subTitle="Tudo que não te contaram sobre paternidade na vida animal, ops..filhote."
            img={coupleAndCat}
            link={ROUTES.CONSTRUCTION}
            key={1}
          />
          <CommunityActionsCard
            title="Brechó Animal"
            subTitle="Roupas novas e usadas para seu filhote."
            img={dogWithHat}
            link={ROUTES.CONSTRUCTION}
            key={2}
          />
          <CommunityActionsCard
            title="8 Dicas de cuidados para seu pet"
            subTitle="Aprenda dicas caseiras de cuidados para seu pet..."
            img={vetAndDog}
            link={ROUTES.CONSTRUCTION}
            key={3}
          />
        </CardContainer>
      </Wrapper>
    </Container>
  );
};
