import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Wrapper,
  MenuIcon,
  CloseIcon,
  ButtonContainer,
  Overlay,
  NavigationMenu,
} from "./styles";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { Button } from "../../atoms/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { Modal } from "../../organisms/Modal";

export const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleCloseMenu = () => setIsMenuOpen(false);

  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        handleCloseMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <Container>
      <Wrapper>
        <Link to={ROUTES.ROOT}>
          <Logo />
        </Link>

        <MenuIcon onClick={toggleMenu} />

        {isMenuOpen && <Overlay onClick={handleCloseMenu} />}

        <NavigationMenu isMenuOpen={isMenuOpen} ref={menuRef}>
          <CloseIcon onClick={handleCloseMenu} />
          
          <Link to={ROUTES.ROOT} onClick={handleCloseMenu}>
            <Button variant="text">Home</Button>
          </Link>
          <Link to={ROUTES.INSTITUTIONAL} onClick={handleCloseMenu}>
            <Button variant="text">Institucional</Button>
          </Link>
          <Link to={ROUTES.ADOPTION} onClick={handleCloseMenu}>
            <Button variant="text">Adoção</Button>
          </Link>
          <Link to={ROUTES.CONSTRUCTION} onClick={handleCloseMenu}>
            <Button variant="text">Blog</Button>
          </Link>

          <ButtonContainer>
            <Button variant="contained" onClick={() => setIsModalOpen(true)}>
              Doar
            </Button>
            <Link to={ROUTES.CONSTRUCTION} onClick={handleCloseMenu}>
              <Button variant="contained" color="secondary">
                Login
              </Button>
            </Link>
          </ButtonContainer>
        </NavigationMenu>        
      </Wrapper>
      
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} isQRCodeModal={true} />
    </Container>
  );
};
