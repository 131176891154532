import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
  width: 100%;
  padding: 16.5rem 2rem;
  display: flex;
  justify-content: center;

  @media (max-width: ${theme.breakpoint.desktop}) {
      padding-inline: 7rem;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
  width: 100%;
  max-width: ${theme.breakpoint.maxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  `}
`;

export const TitleWayToHelp = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standard};
    font-size: 6rem;
    text-align: center;

    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      font-size: 4.5rem;
    }
    
  `}
`;

export const DivWaytoHelp = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5.9rem;
    
    @media (max-width: ${theme.breakpoint.tablet}) {
      align-items: center;
      flex-direction: column;
      gap: 3rem;
    }
  `}
`;

export const Separator = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 0.3rem;
    background-color: ${theme.colors.hex.gray.color300};
    margin: 2rem 0;
    box-shadow: 0rem 0.5rem 0.5rem 0rem rgba(0, 0, 0, 0.25);

    @media (min-width: ${theme.breakpoint.tablet}) {
      display: none;
    }
  `}
`;