import React from "react";
import { breakpoint } from "../../../theme/breakpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ReactComponent as NavIconLeft } from "../../../assets/img/swiper-navicon-left.svg";
import { ReactComponent as NavIconRight } from "../../../assets/img/swiper-navicon-right.svg";
import denis from "../../../assets/img/team/denis.png";
import fernando from "../../../assets/img/team/fernando.png";
import gutz from "../../../assets/img/team/gutz.png";
import giu from "../../../assets/img/team/giu.png";
import filipe from "../../../assets/img/team/filipe.png";
import gui from "../../../assets/img/team/gui.png";
import ale from "../../../assets/img/team/ale.png";
import marcel from "../../../assets/img/team/marcel.png";
import greca from "../../../assets/img/team/greca.png";
import giovanni from "../../../assets/img/team/giovanni.png";
import lipe from "../../../assets/img/team/lipe.png";
import stella from "../../../assets/img/team/stella.png";
import lucas from "../../../assets/img/team/lucas.png";
import victor from "../../../assets/img/team/victor.png";

import {
  Container,
  ImgContainer,
  DescriptionContainer,
  NameContainer,
  PositionContainer,
  ContentContainer,
  ButtonTeam,
  TeamSwiper,
} from "./styles";

export interface TeamMemberProps {
  image: string;
  description: string;
  name: string;
  position: string;
}

export const TeamMember: React.FC<TeamMemberProps> = ({
  image,
  description,
  name,
  position,
}) => (
  <Container>
    <ImgContainer name={name}>
      <img src={image} />
    </ImgContainer>
    <ContentContainer>
      <DescriptionContainer>
        <span>{description}</span>
      </DescriptionContainer>
      <NameContainer>
        <span>{name}</span>
      </NameContainer>
      <PositionContainer>
        <span>{position}</span>
      </PositionContainer>
    </ContentContainer>
  </Container>
);

export const TeamCarousel: React.FC = () => {
  const teamMembersData = [
    {
      image: giu,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Giuliana Santana",
      position: "Desenvolvedora",
    },
    {
      image: marcel,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Marcel",
      position: "Desenvolvedor",
    },
    {
      image: greca,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Guilherme Greca",
      position: "Desenvolvedor",
    },
    {
      image: giovanni,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Giovanni Curcuruto",
      position: "Desenvolvedor",
    },
    {
      image: lipe,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Felipe Tasso",
      position: "Desenvolvedor",
    },
    {
      image: stella,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Stella Siqueira",
      position: "Desenvolvedora",
    },
    {
      image: lucas,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Lucas Bellinazzi",
      position: "Desenvolvedor",
    },
    {
      image: denis,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Denis Rodrigues",
      position: "Desenvolvedor",
    },
    {
      image: gutz,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Guilherme Gutz",
      position: "Desenvolvedor",
    },
    {
      image: filipe,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Filipe Levar",
      position: "Desenvolvedor",
    },
    {
      image: fernando,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Fernando Rebelo",
      position: "Desenvolvedor",
    },
    {
      image: victor,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Victor Jonsson",
      position: "Desenvolvedor",
    },
    {
      image: gui,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Guilherme Pavesi",
      position: "Gerente Eitree Academy",
    },
    {
      image: ale,
      description: `"A SOLPRA é mais do que uma organização; somos uma comunidade de pessoas que compartilham o compromisso com a vida animal."`,
      name: "Alessandra Arno",
      position: "Gerente de Projeto",
    },
  ];

  return (
    <TeamSwiper>
      <Swiper
        modules={[Navigation, Autoplay]}
        centeredSlides={true}
        navigation={{ nextEl: ".nextButton", prevEl: ".previousButton" }}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          // On Swiper, it uses the intervals above the breakpoint, as opposed to the usual use of the intervals below the breakpoint
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // for example, above the breakpoint tablet (768px), it will behave like that
          [parseInt(breakpoint.tablet, 10)]: {
            slidesPerView: 1.5,
            spaceBetween: 30,
          },
          [parseInt(breakpoint.desktop, 10)]: {
            slidesPerView: 1.5,
            spaceBetween: 40,
          },
        }}
      >
        {teamMembersData.map((teamMember, index) => (
          <SwiperSlide key={index}>
            <TeamMember {...teamMember} />
          </SwiperSlide>
        ))}
      </Swiper>

      <ButtonTeam>
        <button className="previousButton">
          <NavIconLeft />
        </button>
        <button className="nextButton">
          <NavIconRight />
        </button>
      </ButtonTeam>
    </TeamSwiper>
  );
};
