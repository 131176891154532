import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.hex.black.color900};
    padding-bottom: 8.4rem;
    padding-top: 5rem;

    @media (max-width: ${theme.breakpoint.desktop}) {
      padding: 0 2rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      padding: 0 1.5rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      padding-top: 1rem;
    }

    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      padding: 0 0.8rem;
    }

    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      padding: 0 0.5rem;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  `}
`;

export const TitleTextContainer = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.standard};
    font-size: 6rem;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 3.0rem
    text-align: center;

    @media (max-width: ${theme.breakpoint.desktop}) {
      padding-top: 4rem;
      font-size: 4.8rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 3.8rem;
      padding-top: 3rem;

    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 3rem;
    }
  `}
`;

export const SubTitleTextContainer = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.standard};
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5.8rem;
    padding-top: 2rem;

    @media (max-width: ${theme.breakpoint.desktop}) {
      padding-top: 3rem;
      font-size: 2.8rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 2.4rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.5rem;
      padding-top: 1.8rem;
    }
  `}
`;

export const StyledSwiper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 1rem;

    .swiper-pagination {
      position: relative;
      width: 100%;
      bottom: -11.8rem;
      margin-bottom: 15.4rem;
    }

    .swiper-pagination-bullet {
      background-color: ${theme.colors.hex.primary.color600};
      width: 2rem;
      height: 2rem;
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (max-width: ${theme.breakpoint.desktop}) {
      .swiper-slide {
        width: calc((100% - 4rem) / 3) !important;
      }
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      .swiper-slide {
        width: calc((100% - 3rem) / 2) !important;
      }
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      .swiper-slide {
        width: 100% !important;
      }
    }
  `}
`;
