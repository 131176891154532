import styled, { css, keyframes } from "styled-components";

export const ConstructionWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;

    @media (max-width: ${theme.breakpoint.tablet}) {
      min-height: 75vh;
      }
  `}
`;

export const ConstructionTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    font-size: 4rem;
    line-height: 1.2;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2.8rem;
      padding-bottom: 2rem;
      line-height: 1.2;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 2rem;
      line-height: 1.3;
      padding-bottom: 1.5rem;
    }
  
    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.8rem;
      line-height: 1.4;
      padding-bottom: 1rem;
    }
  
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      font-size: 1.6rem;
      line-height: 1.4;
      padding-bottom: 0.8rem;
    }
  
    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      font-size: 1.4rem; 
      line-height: 1.5;
      padding-bottom: 0.6rem;
    }
  `}
`;

const moveUpAndDown = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2rem);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ConstructionImage = styled.img`
  ${({ theme }) => css`
    width: 80rem;
    height: auto;
    animation: ${moveUpAndDown} 4s infinite;

    @media (max-width: ${theme.breakpoint.desktop}) {
      width: 60%;
      max-height: 50rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      width: 70%;
      max-height: 40rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      width: 80%;
      max-height: 30rem;
    }

    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      width: 85%;
      max-height: 25rem;
    }

    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      width: 90%; 
      max-height: 20rem;
    }
  `}
`;
