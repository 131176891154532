export const breakpoint = {
  // Max width for large desktop screens (in rem because it is not used as a media query)
  maxWidth: "129rem", // 129rem = 1290px (large desktops)

  // Breakpoints for responsive design (in px because they are used as media query)
  desktop: "1024px", // (small desktops, laptops)
  tablet: "768px", // (tablets)
  mobileLarge: "480px", // (large mobile devices, phablets)
  mobileMedium: "375px", // (medium mobile devices)
  mobileSmall: "320px", // (small mobile devices)

  carouselBreakpoint: "1500px", // Custom breakpoint for carousel
};
