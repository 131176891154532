import styled, { css } from "styled-components";

export const HeroTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color200};
    font-size: 6rem;
    font-style: normal;
    margin-left: 2rem;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;
    max-width: 63.2rem;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 5rem;
      margin-bottom: 3rem;
    }
    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 4rem;
      margin-bottom: 2.5rem;
    }
    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      font-size: 2.4rem;
      margin-bottom: 1.5rem;
    }
    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  `}
`;

export const HeroText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color200};
    margin: 3rem 2rem 6rem 2rem;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.6rem;
    max-width: 61rem;
    text-align: justify;

    @media (max-width: ${theme.breakpoint.desktop}) {
      margin-top: 2rem;
      margin-bottom: 4rem;
    }
    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 2.4rem;
      line-height: 3rem;
      margin-top: 1rem;
      margin-bottom: 3rem;
    }
    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 2rem;
      line-height: 2.6rem;
      margin-bottom: 2rem;
    }
    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 1.5rem;
    }
    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 1rem;
    }
  `}
`;

export const ButtonContainer = styled.div`
  display: inline-block;
  margin-left: 2rem;
`;

export const BannerTitleMission = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standart};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;
    width: 100rem;
    max-width: 100%;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 5.6rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 4.8rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 3.4rem;
    }
  `}
`;

export const BannerTitleWorks = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.standart};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;
    width: 100rem;
    max-width: 100%;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 5.2rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 4.4rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 3.8rem;
    }
  `}
`;

export const TitleColored = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
  `}
`;

export const BannerParagraphWorks = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.lato};
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 13rem;
    width: 100rem;
    max-width: 100%;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2rem;
      margin-top: 2.6rem;
      margin-bottom: 7rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 1.8rem;
      margin-top: 2rem;
      margin-bottom: 5rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.4rem;
      margin-top: 1.6rem;
      margin-bottom: 3.2rem;
    }
  `}
`;

export const ParagraphColored = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    text-decoration-line: underline;
  `}
`;

export const BannerTitlePart = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standart};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;
    width: 100rem;
    max-width: 100%;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 5.6rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 4.6rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 4rem;
    }
  `}
`;

export const BannerParagraphPart = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.lato};
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 6rem;
    max-width: 100rem;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2.1rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 1.9rem;
      margin-top: 2rem;
      margin-bottom: 4rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.5rem;
      margin-top: 1.2rem;
      margin-bottom: 2.4rem;
    }
  `}
`;

export const BannerTitleVolunteers = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 5.16rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 4.8rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 3.2rem;
    }
  `}
`;

export const BannerContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    @media (max-width: ${theme.breakpoint.tablet}) {
      flex-direction: column;
    }
  `}
`;

export const BannerParagraphVolunteers = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.lato};
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 13rem;
    width: 100%;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2rem;
      margin-bottom: 6rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 1.8rem;
      margin-bottom: 0rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.4rem;
      margin-top: 1.6rem;
    }
  `}
`;

export const ParagraphColoredVolunteers = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
  `}
`;

export const BannerImageVolunteers = styled.img`
  ${({ theme }) => css`
    display: block;
    max-width: 76.6rem;
    height: auto;
    border-radius: 1rem;
    margin-top: 3rem;
    margin-left: 1rem;
    width: 60%;

    @media (max-width: ${theme.breakpoint.tablet}) {
      width: 100%;
      margin-left: 0rem;
    }
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    display: inline;
    font-size: 6rem;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;
    max-width: 100%;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 5rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 4rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 3rem;
    }
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    margin-top: 2rem;
    margin-bottom: 13rem;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.6rem;

    @media (max-width: ${theme.breakpoint.desktop}) {
      font-size: 2.4rem;
      margin-bottom: 7rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      font-size: 2rem;
      margin-bottom: 5rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      font-size: 1.6rem;
      margin-bottom: 3rem;
    }
  `}
`;
