import React, { useState } from "react";
import CatAndGirl from "../../../assets/img/CatAndGirl.svg";
import Volunteers from "../../../assets/img/Volunteers.svg";
import dogWayToHelp from "../../../assets/img/dogWayToHelp.svg";
import { Container, Wrapper, TitleWayToHelp, DivWaytoHelp, Separator } from "./styles";
import { Card } from "../../molecules/Card/index";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../Router";
import { Modal } from "../Modal";

export const ContainerHelp: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  
  return (
    <Container>
      <Wrapper>
        <TitleWayToHelp>Maneiras de Ajudar</TitleWayToHelp>
        <DivWaytoHelp>
          <Card
            title="Adoção"
            altText="Adoção"
            description="Encontre seu novo melhor amigo! Adote um filhote e transforme vidas hoje mesmo."
            imageSrc={CatAndGirl}
            buttonText="ADOTE"
            onButtonClick={() => handleNavigate(ROUTES.ADOPTION)}
            key={1}
          />
          <Separator />
          <Card
            title="Faça uma Doação"
            altText="Faça uma Doação"
            description="Contribua com qualquer valor e ajude a manter a SOLPRA manter e preservar a vida animal."
            imageSrc={Volunteers}
            buttonText="QUERO DOAR"
            onButtonClick={() => setIsModalOpen(true)}
            key={2}
          />
          <Separator />
          <Card
            title="Achados e Perdidos"
            altText="Achados e Perdidos"
            description="Em caso de catástrofes ou mesmo de aparecimento do seu filho a SOLPRA estará aqui para ajudar a encontrá-lo."
            imageSrc={dogWayToHelp}
            buttonText="ENCONTRE SEU PET"
            onButtonClick={() => handleNavigate(ROUTES.ADOPTION)}
            key={3}
          />
        </DivWaytoHelp>
      </Wrapper>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} isQRCodeModal={true} />
    </Container>
  );
};
