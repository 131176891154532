import styled, { css } from "styled-components";

export const ScrollButtonWrapper = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${theme.colors.hex.white};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s ease;

    &:hover {
      transform: scale(1.1);
      background-color: ${theme.colors.hex.lightGrey};
    }

    @media (max-width: ${theme.breakpoint.desktop}) {
      width: 6rem;
      height: 6rem;
      bottom: 1.5rem;
      right: 1.5rem;
    }

    @media (max-width: ${theme.breakpoint.tablet}) {
      width: 5rem;
      height: 5rem;
      bottom: 1.2rem;
      right: 1.2rem;
    }

    @media (max-width: ${theme.breakpoint.mobileLarge}) {
      width: 4rem;
      height: 4rem;
      bottom: 1rem;
      right: 1rem;
    }

    @media (max-width: ${theme.breakpoint.mobileMedium}) {
      width: 3.5rem;
      height: 3.5rem;
      bottom: 0.8rem;
      right: 0.8rem;
    }

    @media (max-width: ${theme.breakpoint.mobileSmall}) {
      width: 3rem;
      height: 3rem;
      bottom: 0.5rem;
      right: 0.5rem;
    }
  `}
`;
